import React, { useEffect, useState } from "react";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../firebase";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { motion } from "framer-motion";
import "./Gallery.css";

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const listRef = ref(storage, "files/");
        const res = await listAll(listRef);
        const urls = await Promise.all(
          res.items.map((itemRef) => getDownloadURL(itemRef))
        );

        // Sort images numerically by filename
        const sortedUrls = urls.sort((url1, url2) => {
          const num1 = extractNumberFromFilename(url1);
          const num2 = extractNumberFromFilename(url2);
          return num1 - num2;
        });

        setImages(sortedUrls);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  // Helper function to extract numeric part from filename
  const extractNumberFromFilename = (url) => {
    const filename = extractFilename(url);
    return parseInt(filename, 10) || 0;
  };

  // Helper function to extract filename from URL
  const extractFilename = (url) => {
    const startIndex = url.lastIndexOf("/") + 1;
    const endIndex = url.lastIndexOf("?");
    return url.substring(startIndex, endIndex === -1 ? url.length : endIndex);
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="section"
    >
      <div className="container mx-auto h-full relative">
        <h1 className="h1 text-center mb-6 tracking-wide">Gallery</h1>
        <p className="text-center mb-4 px-6">
          Explore the gallery to see my best photographs. Each image tells a
          story and captures a moment with creativity and precision, from the
          dynamic energy of sports to the sleek elegance of cars.
        </p>
        {loading ? (
          <div
            className="flex justify-center items-center h-full"
            style={{ minHeight: "400px" }}
          >
            <span className="loading loading-spinner loading-md"></span>
          </div>
        ) : (
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 750: 2, 900: 3, 1200: 4 }}
          >
            <Masonry>
              {images.map((url, index) => (
                <motion.div
                  key={index}
                  className="overflow-hidden rounded-lg blurred-img"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                >
                  <div
                    className="blurred-background"
                    style={{ backgroundImage: `url(${url}-small.jpg)` }}
                  ></div>
                  <img
                    src={url}
                    alt={`Gallery image ${index + 1}`}
                    className="w-full h-full object-cover p-2"
                    loading="lazy"
                    onLoad={(e) =>
                      e.target.parentElement.classList.add("loaded")
                    }
                  />
                </motion.div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        )}
      </div>
    </motion.section>
  );
};

export default Gallery;
