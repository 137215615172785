import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCK1DdvqPysCzhrvxnhlJf15Qsoa3GM08s",
  authDomain: "amine-2ddea.firebaseapp.com",
  projectId: "amine-2ddea",
  storageBucket: "amine-2ddea.appspot.com",
  messagingSenderId: "88838903534",
  appId: "1:88838903534:web:c3409ce555f513ffd505ff",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Get a Firestore instance
const db = getFirestore(app);

// Initialize Firebase Storage and get a reference to the service
const storage = getStorage(app);

export { auth, db, storage };
export default app;
