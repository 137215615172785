import React, { useContext } from "react";
import CursorProvider, { CursorContext } from "../context/CursorContext";
import {
  ImFacebook,
  ImTwitter,
  ImPinterest,
  ImInstagram,
  ImYoutube,
  ImLinkedin,
} from "react-icons/im";

const Socials = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  return (
    <div
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className="hidden xl:flex ml-24"
    >
      <ul className="flex gap-x-4">
        <li>
          <a
            href="https://www.facebook.com/AmineamorArchitecte"
            target="_blank"
          >
            <ImFacebook />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/2a_vision/" target="_blank">
            <ImInstagram />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/@2A_Vision" target="_blank">
            <ImYoutube />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/amine-amor-597453234/"
            target="_blamk"
          >
            <ImLinkedin />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Socials;
