import React, { useContext } from "react";
import Gallery from "../components/Gallery";
import { motion } from "framer-motion";
import { transition1 } from "../transitions";
import { CursorContext } from "../context/CursorContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Portfolio = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  const videoUrls = [
    "https://www.youtube.com/embed/NwjRxrEUiuc?si=2_kzn0bGVt7R-Qk9",
    "https://www.youtube.com/embed/9IJ_vM6HtuI?si=GQOH-e4eYGS2UJk7",
    "https://www.youtube.com/embed/JxDc5ujZUIQ?si=MRW5njKF9yHKz33_",
    "https://www.youtube.com/embed/gDJqRLawxSY?si=jnuWBZ4FIJrzCLn-",
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition1}
      className="section overflow-x-hidden"
    >
      <div className="container mx-auto h-full relative">
        <motion.div
          initial={{ opacity: 0, y: "-80%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "-80%" }}
          transition={transition1}
          className="flex flex-col lg:flex-row h-full items-center justify-start gap-x-24 text-center lg:text-left pt-24 lg:pt-36"
        >
          <div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className="flex flex-col lg:items-start lg:px-7 lg:w-1/2"
          >
            <h1 className="h1 tracking-wide">Some of my work</h1>
            <p className="">
              Explore my YouTube channel to see my filming skills in action.
              From commercial and corporate videos to car, sports, music, real
              estate, and social media projects, I bring a unique perspective to
              each genre. My channel is a testament to my dedication to
              capturing compelling visuals and storytelling through the lens.
              <br />
              <br />
            </p>
            <a
              href="https://www.youtube.com/@2A_Vision"
              target="_blank"
              rel="noopener noreferrer"
              className="align-middle mb-6 select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 bg-gradient-to-tr from-gray-900 to-gray-800 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] rounded-full"
            >
              Visit My Channel
            </a>
          </div>
          <div className="lg:w-1/2 w-full">
            <Slider {...settings}>
              {videoUrls.map((url, index) => (
                <div key={index} className="aspect-w-16 aspect-h-9 sm:m-x-2">
                  <iframe
                    src={url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full"
                  ></iframe>
                </div>
              ))}
            </Slider>
          </div>
        </motion.div>
      </div>
      <Gallery />
    </motion.section>
  );
};

export default Portfolio;
